body, html {
    height: 100%;
    width: 100%;
    position: fixed;
    overflow: hidden;
}

#root {
    height: 100%;
}

div {
    outline: none;
}
